import { STATIC_DOMAIN } from "@/constants/environment";
import Button from "@/components/Button";
import Link from "next/link";

export default function Page404() {
  return (
    <div className="bg-grey-100 !h-screen w-screen flex flex-col justify-center items-center">
      <img
        src={`${STATIC_DOMAIN}/images/not_found.svg`}
        alt="not-found"
        className="w-fit"
      />
      <div className="text-base md:text-fs-20 text-grey-800 font-semibold mt-6 mb-4 text-center">
        Rất tiếc đã có lỗi trong quá trình xử lý!
      </div>
      <Link href={"/"}>
        <Button
          color="primary"
          btnType="filled"
          onClick={() => {}}
          prefixIcon={<i className="mrv mrv-arrow_back text-fs-20"> </i>}
          className="h-8 text-fs-14"
        >
          Quay lại
        </Button>
      </Link>
    </div>
  );
}
